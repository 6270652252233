import React from "react"
import SEO from "../../../components/seo"
import { Helmet } from 'react-helmet'
import Layout from "../../../components/layout"
import TwoColumn from "../../../components/two-column"

const developersContent = {
  title: "Customer growth calculator",
  description: [
    {
      text: "Our free customer growth calculator helps you predict the future of your subscription business. Set a customer base goal and see how long it'll take to achieve it taking into account both your current customer base and the impact of churn on your potential growth."
    }
  ],
  sectionCalculator: true,
  image: require('../../../images/tools-customer-growth-calculator.svg'),
  isBanner: true,
  svg: true,
  imageName: 'Tools-Customer-Growth-Calculator',
  backgroundColor: '#f3f5fb',
  textColor: '#252422',
  imagePosition: 'right'
}

const chargeBackCalc = () => {
  return (
    <Layout className="chargeback-calc">
      <SEO 
        title="Customer Growth Calculator | Billsby" 
        description="Calculate how your customer growth will impact the profitability of your subscription business and impact your monthly recurring revenue." 
        url="https://www.billsby.com/resources/tools/customer-growth-calculator"
      />

      <TwoColumn content={developersContent}/>

        <div className="container">
          <div className="calc">
            <div className="calculator" data-calc-id="KK8uuj34QBZTktC77"></div>

            <Helmet>
              <script
                async={true}
                id="convertcalculator-embedder-axP7WcQqWW5QBboJh" 
                className="convertcalculator-async-script-loader"
                type="text/javascript"
                src="https://app.convertcalculator.co/embed.js"
              ></script>
            </Helmet>

            {/* <script
              type="text/javascript" id="convertcalculator-embedder-axP7WcQqWW5QBboJh" className="convertcalculator-async-script-loader"
              dangerouslySetInnerHTML={{__html: `
                (function() {
                  function async_load(){
                    var s = document.createElement("script");
                    s.type = "text/javascript";
                    s.async = true;
                    var url = 'https://app.convertcalculator.co/embed.js';
                    s.src = url + ( url.indexOf("?") >= 0 ? "&" : "?") + "ref=" + encodeURIComponent(window.location.href);
                    var embedder = document.getElementById('convertcalculator-embedder-axP7WcQqWW5QBboJh');
                    embedder.parentNode.insertBefore(s, embedder);
                  }
                  if (window.attachEvent)
                    window.attachEvent("onload", async_load);
                  else
                    window.addEventListener("load", async_load, false);
                })();
                `,
              }}
            /> */}
          </div>
        </div>
    </Layout>
  )
}

export default chargeBackCalc